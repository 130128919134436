.mobile-nav.open {
  display: initial;
}
.mobile-nav {
  display: none;
}

@media (max-width: 1023px) {
  .mobile-nav.open {
    left: 0;
    transition: left ease-in 0.3s;
    box-shadow: 0px 20px 20px 4px rgba(0, 0, 0, 0.085);
  }
  .mobile-notification {
    top: 3px;
    right: 3px;
  }
}
