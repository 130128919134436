/* tailwind required imports */
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* import global stylesheet */
@import "./components/css/site.css";

/* index styles... */
body {
  margin: 0;
  
}
::selection {
  background: #97dbf1;
}
