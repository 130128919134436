.error{
  color: red;
  font-size: 14px;
}

.min108{
  min-height: 108px;
  
  }
/* CUSTOM CSS FOR TABLES */

/* -- Meter Values CSS --  */
#mobile-nav{
  flex-direction: column;
  align-items: center;
}
td#last {
  padding-bottom: 2em;
}
/* -- Meter Values CSS --  */

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

#loading td {
  width: 100%;
}

/* desktop device status colours - full width*/

.status-colour-red {
  background-color:  #ff0000 ;
}
.status-colour-green {
  background-color: #00ff00 ;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Mobile device status colours - 50% width*/
.status-colour-red {
  background-image: linear-gradient(to right, transparent 50%, #ff0000 50%)!important;
  background-color: transparent;
}
.status-colour-green {
  background-image: linear-gradient(to right, transparent 50%, #00ff00 50%)!important;
  background-color: transparent;
}

/* loading tr and td for all tables */
  #loading {
    margin-left: -65%;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background-color: rgb(40 100 153 / 0.05);
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    top: 0;
    left: 1px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    padding-top: 1rem;
    font-weight: 700;
    padding-left: 2%;
  }



  #loading td:nth-of-type(1):before {
    content: '';
  }
/* metercode table header labels */
  .metercode-table td:nth-of-type(1):before {
    content: 'Meter Number:';
  }
  .metercode-table td:nth-of-type(2):before {
    content: 'Unique ID:';
  }
  .metercode-table td:nth-of-type(3):before {
    content: 'DeviceEUI:';
  }
  .metercode-table td:nth-of-type(4):before {
    content: 'Customer:';
  }

  /* meter readings table header labels */
  .meter-reading-table td:nth-of-type(1):before {
    content: 'Meter Number:';
  }
  .meter-reading-table td:nth-of-type(2):before {
    content: 'Meter Value:';
  }
  .meter-reading-table td:nth-of-type(3):before {
    content: 'Date/Time:';
  }
  .meter-reading-table td:nth-of-type(4):before {
    content: 'Created by:';
  }


 /* device status table header labels */
  .device-status-table td:nth-of-type(1):before {
    content: 'Name:';
  }
  .device-status-table td:nth-of-type(2):before {
    content: 'DeviceId:';
  }
  .device-status-table td:nth-of-type(3):before {
    content: 'Gateway(s):';
  }
  .device-status-table td:nth-of-type(4):before {
    content: 'Battery Level:';
  }
  .device-status-table td:nth-of-type(5):before {
    content: 'Last Webhook Received:';
  }
  .device-status-table td:nth-of-type(6):before {
    content: 'Webhook Message:';
  }
  
 /* Meter Values table header labels */
 .meter-values-table td:nth-of-type(1):before {
  content: 'Meter Number:';
}
.meter-values-table td:nth-of-type(2):before {
  content: 'Customer:';
}
.meter-values-table td:nth-of-type(3):before {
  content: 'Calculated Value:';
}

 /* alert history table header labels */
  .alert-history-table td:nth-of-type(1):before {
    content: 'Date / Time:';
  }
  .alert-history-table td:nth-of-type(2):before {
    content: 'Meter:';
  }
  .alert-history-table td:nth-of-type(3):before {
    content: 'Farm:';
  }
  .alert-history-table td:nth-of-type(4):before {
    content: 'Alert Trigger:';
  }




 /* alert setup table header labels */
  .alert-setup-table td:nth-of-type(1):before {
    content: 'Farm:';
  }
  .alert-setup-table td:nth-of-type(2):before {
    content: 'Meter:';
  }
  .alert-setup-table td:nth-of-type(3):before {
    content: 'Alert Trigger:';
  }



 /* meter table header labels */
  .meters-table td:nth-of-type(1):before {
    content: 'Meter Name:';
  }
  .meters-table td:nth-of-type(2):before {
    content: 'Farm Name:';
  }
  .meters-table td:nth-of-type(3):before {
    content: 'MeterCode:';
  }




 /* Farm table header labels */
  .farms-table td:nth-of-type(1):before {
    content: 'Farm Name:';
  }
  .farms-table td:nth-of-type(2):before {
    content: 'Farm Size:';
  }


}

.copy-cursor{
  cursor: url(../assets/copy-icon.svg), auto;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #97DBF1;
  color: #00369C;
  font-weight: 500;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 100%;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  z-index: 1;
}

.save-disabled {
  color: grey; 
}
.save {
  color: #286499; 
}

.max-h-70vh {
  max-height: 70vh;
}